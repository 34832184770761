import React from "react";
import PropTypes from 'prop-types';
import MuiButton from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = color => makeStyles(theme => ({
	/**
	 * Color styles for text (aka flat) buttons
	 * @see https://github.com/mui-org/material-ui/blob/8995f085904eb55bcb5861fb6d8a32fbd38d72eb/packages/material-ui/src/Button/Button.js#L50-L59
	 */
	textCustom: {
		color: theme.palette[color].main,
		"&:hover": {
			backgroundColor: fade(theme.palette[color].main, theme.palette.action.hoverOpacity),
			// Reset on touch devices, it doesn't add specificity
			"@media (hover: none)": {
				backgroundColor: "transparent"
			}
		},
		"&:focus,&:hover": {
			color: theme.palette[color].main
		}
	},

	/**
	 * Color styles for outlined buttons.
	 * @see https://github.com/mui-org/material-ui/blob/8995f085904eb55bcb5861fb6d8a32fbd38d72eb/packages/material-ui/src/Button/Button.js#L84-L92
	 */
	outlinedCustom: {
		color: theme.palette[color].main,
		border: `1px solid ${fade(theme.palette[color].main, 0.5)}`,
		"&:hover": {
			backgroundColor: fade(theme.palette[color].main, theme.palette.action.hoverOpacity),
			border: `1px solid ${theme.palette[color].main}`,
			// Reset on touch devices, it doesn't add specificity
			"@media (hover: none)": {
				backgroundColor: "transparent"
			}
		},
		"&:focus,&:hover": {
			color: theme.palette[color].main
		}
	},

	/**
	 * Color styles for contained (aka raised) buttons
	 * @see https://github.com/mui-org/material-ui/blob/8995f085904eb55bcb5861fb6d8a32fbd38d72eb/packages/material-ui/src/Button/Button.js#L131-L141
	 */
	containedCustom: {
		color: theme.palette.getContrastText(theme.palette[color].main),
		backgroundColor: theme.palette[color].main,
		"&:hover": {
			backgroundColor: theme.palette[color].dark,
			// Reset on touch devices, it doesn't add specificity
			"@media (hover: none)": {
				backgroundColor: theme.palette[color].main
			}
		},
		"&:focus,&:hover": {
			color: theme.palette.getContrastText(theme.palette[color].main)
		}
	}
}));

/**
 * React material-ui button extension component, expands on "color" functionality
 * @see https://codesandbox.io/s/rj34pyo75p
 * Concept for custom button colors taken from the above code sandbox. Modified to work correctly with our custom theme.
 * @param {string} variant - button type (text, outlined, contained) default: "text"
 * @param {string} color - can be any color defined in custom theme palette ex: primary, secondary, red, green..., default: "grey"
 * @param {string} className - classes to apply to mui button
 * @param {React.Ref} forwardRef - required to allow ref to be passed through to MUI button https://reactjs.org/docs/forwarding-refs.html
 * @param {misc} ...buttonProps - all other props for Material UI Buttons
 */
function CustomButton({ variant, color, children, className, forwardRef, ...buttonProps }) {
	const classes = useStyles(color)();

	return (
		<MuiButton ref={forwardRef} className={`${classes[variant + "Custom"]} ${className}`} variant={variant} {...buttonProps} >
			{children}
		</MuiButton>
	);
};

CustomButton.propTypes = {
	variant: PropTypes.string,
	color: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node
}

CustomButton.defaultProps = {
	variant: "text",
	color: "grey"
}

export default React.forwardRef((props, ref) => (<CustomButton forwardRef={ref} {...props}></CustomButton>));