const yellow = {
	50: "#FAF8E6",
	100: "#f5f0cc",
	200: "#ece199",
	300: "#e2d366",
	400: "#d9c433",
	500: "#cfb500",
	600: "#a69100",
	700: "#685b00",
	800: "#534800",
	900: "#292400",
	A100: "#ffff8d",
	A200: "#ffff00",
	A400: "#ffea00",
	A700: "#ffd600"
};

export default yellow;
