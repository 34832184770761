const purple = {
	50: "#F3EEFF",
	100: "#e7dcff",
	200: "#cfb9ff",
	300: "#b896ff",
	400: "#a073ff",
	500: "#8850ff",
	600: "#6d40cc",
	700: "#523099",
	800: "#362066",
	900: "#1b1033",
	A100: "#ea80fc",
	A200: "#e040fb",
	A400: "#d500f9",
	A700: "#aa00ff"
};

export default purple;
