import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(8, 0)
	},
	content: {
		padding: theme.spacing(6, 6)
	},
	title: {
		fontWeight: 400
	},
	hr: {
		border: 0,
		borderTop: `1px solid ${theme.palette.grey.light}`
	}
}));

export default function HomeSection(props) {
	const classes = useStyles();

	return (
		<div id={props.id} className={classes.root}>
			<Typography
				className={classes.title}
				variant="h2"
				component="h1"
				gutterBottom
			>
				{props.sectionTitle}
			</Typography>
			<hr className={classes.hr} />

			<div className={classes.content}>{props.children}</div>
		</div>
	);
}
