const blue = {
	50: "#E6F1FC",
	100: "#cce3fa",
	200: "#99c7f5",
	300: "#66acef",
	400: "#3390ea",
	500: "#0074e5",
	600: "#005db7",
	700: "#004689",
	800: "#002e5c",
	900: "#00172e",
	A100: "#82b1ff",
	A200: "#448aff",
	A400: "#2979ff",
	A700: "#2962ff"
};

export default blue;
