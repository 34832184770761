const common = {
	black: "#000",
	white: "#fff",
	dark: "#2f2f2f",
	light: "#F1F1F1",
	lalight: "#FAFAFA",
	ladark: "#292B2D"
};

export default common;
