import { useEffect } from 'react';

const useAddScript = (url) => {
	useEffect(() => {
		const scriptElement = document.createElement('script');

		scriptElement.src = url;
		scriptElement.async = true;

		document.body.appendChild(scriptElement);

		return () => {
			document.body.removeChild(scriptElement);
		};
	}, [url]);
};

export default useAddScript;
