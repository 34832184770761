const orange = {
	50: "#FCEBE6",
	100: "#F9D6CC",
	200: "#F2AE99",
	300: "#EC8566",
	400: "#E55D33",
	500: "#DF3400",
	600: "#B22A00",
	700: "#861F00",
	800: "#591500",
	900: "#2D0A00",
	A100: "#ffd180",
	A200: "#ffab40",
	A400: "#ff9100",
	A700: "#ff6d00"
};

export default orange;
