const teal = {
	50: "#E6F2F5",
	100: "#cce6eb",
	200: "#99cdd7",
	300: "#66b3c4",
	400: "#339ab0",
	500: "#00819c",
	600: "#00677d",
	700: "#004d5e",
	800: "#00343e",
	900: "#001a1f",
	A100: "#a7ffeb",
	A200: "#64ffda",
	A400: "#1de9b6",
	A700: "#00bfa5"
};

export default teal;
