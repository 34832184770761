import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

import CustomButton from "../CustomButton/CustomButton";

const useStyles = makeStyles(theme => ({
	cardHeader: {
		backgroundColor:
			theme.palette.type === "dark"
				? theme.palette.grey[700]
				: theme.palette.grey[100]
	},
	content: {
		justifyContent: "center",
		alignItems: "baseline"
	},
	cardMedia: {
		height: 400
	}
}));

export default function HomeCard(props) {
	const classes = useStyles();
	const { cardContent } = props;
	return (
		<Card
			style={{
				display: "flex",
				justifyContent: "space-between",
				flexDirection: "column"
			}}
		>
			<CardHeader
				title={cardContent.title}
				titleTypographyProps={{ align: "center" }}
				subheaderTypographyProps={{ align: "center" }}
				className={classes.cardHeader}
			/>
			<CardContent>
				<div className={classes.content}>
					<Typography
						variant="body1"
						color="textPrimary"
						component="p"
					>
						{cardContent.content}
					</Typography>
					{cardContent.contentListItems && (
						<ul>
							{cardContent.contentListItems.map(item => (
								<Typography
									component="li"
									variant="subtitle1"
									key={item}
								>
									{item}
								</Typography>
							))}
						</ul>
					)}
				</div>
			</CardContent>
			<CardActions>
				<CustomButton
					fullWidth
					variant="contained"
					color="primary"
					href={cardContent.url}
					target="_blank"
				>
					Learn More
				</CustomButton>
			</CardActions>
		</Card>
	);
}
