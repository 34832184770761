const pink = {
	50: "#FBE6F8",
	100: "#f4b3e9",
	200: "#ed80da",
	300: "#e966d2",
	400: "#e133c3",
	500: "#da00b4",
	600: "#ae0090",
	700: "#83006c",
	800: "#570048",
	900: "#2c0024",
	A100: "#ff80ab",
	A200: "#ff4081",
	A400: "#f50057",
	A700: "#c51162"
};

export default pink;
