import { createTheme } from "@material-ui/core/styles";
import common from "../colors/common";
import green from "../colors/green";
import grey from "../colors/grey";
import orange from "../colors/orange";
import pink from "../colors/pink";
import purple from "../colors/purple";
import red from "../colors/red";
import teal from "../colors/teal";
import yellow from "../colors/yellow";
import blue from "../colors/blue";

const brandingV2 = {
	HeaderBackgroundColor: "#FAFAFA",
	HeaderBorderColor: "#EFEFEF",
	DarkFontColor: "#292B2D",
	PrimaryButton: {
		BackgroundColor: "#00758C",
		ActiveBackgroundColor: "#004d5e",
		FontColor: "#FFFFFF"
	}
}

const colors = {
	primary: {
		extraLight: teal[100],
		light: teal[200],
		main: teal[500],
		dark: teal[700]
	},
	secondary: {
		light: orange[200],
		main: orange[500],
		dark: orange[700]
	},
	grey: {
		light: grey[200],
		main: grey[500],
		dark: grey[700]
	},
	red: {
		light: red[200],
		main: red[500],
		dark: red[700]
	},
	orange: {
		light: orange[200],
		main: orange[500],
		dark: orange[700]
	},
	yellow: {
		light: yellow[200],
		main: yellow[500],
		dark: yellow[700]
	},
	green: {
		light: green[200],
		main: green[500],
		dark: green[700]
	},
	teal: {
		light: teal[200],
		main: teal[500],
		dark: teal[700]
	},
	blue: {
		light: blue[200],
		main: blue[500],
		dark: blue[700]
	},
	purple: {
		light: purple[200],
		main: purple[500],
		dark: purple[700]
	},
	pink: {
		light: pink[200],
		main: pink[500],
		dark: pink[700]
	}
}

const status = {
	error: {
		light: red[200],
		main: red[500],
		dark: red[700]
	},
	warning: {
		light: yellow[200],
		main: yellow[500],
		dark: yellow[700]
	},
	info: {
		light: blue[200],
		main: blue[500],
		dark: blue[700]
	},
	success: {
		light: green[200],
		main: green[500],
		dark: green[700]
	}
};

const customTheme = createTheme({
	palette: {
		background: {
			default: common.white
		},
		text: {
			primary: common.dark,
			secondary: grey[600]
		},
		dark: common.dark,
		light: common.light,
		lalight: common.lalight,
		ladark: common.ladark,
		brandingV2: brandingV2,
		...colors,
		...status
	},
	status: status,
	icons: {
		sidebarIcons: {
			minWidth: "2.5em"
		}
	},
	typography: {
		fontFamily: [
			'"Open Sans"',
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(","),
		h1: {
			fontSize: "3.815rem",
			fontWeight: 300,
			lineHeight: 1.167,
			letterSpacing: "-0.01562em"
		},
		h2: {
			fontSize: "3.052rem ",
			fontWeight: 300,
			lineHeight: 1.2,
			letterSpacing: "-0.00833em"
		},
		h3: {
			fontSize: "2.441rem",
			fontWeight: 400,
			lineHeight: 1.167,
			letterSpacing: "0em"
		},
		h4: {
			fontSize: "1.953rem",
			fontWeight: 400,
			lineHeight: 1.235,
			letterSpacing: "0.00735em"
		},
		h5: {
			fontSize: "1.563rem",
			fontWeight: 400,
			lineHeight: 1.334,
			letterSpacing: "0em"
		},
		h6: {
			fontSize: "1.25rem",
			fontWeight: 500,
			lineHeight: 1.6,
			letterSpacing: "0.0075em",
			textTransform: "none"
		},
		subtitle1: {
			fontSize: "1rem",
			fontWeight: 400,
			lineHeight: 1.75,
			letterSpacing: "0.00938em"
		},
		subtitle2: {
			fontSize: "0.8rem",
			fontWeight: 500,
			lineHeight: 1.57,
			letterSpacing: "0.00714em"
		},
		body1: {
			fontSize: "1rem",
			fontWeight: 400,
			lineHeight: 1.5,
			letterSpacing: "0.00938em"
		},
		body2: {
			fontSize: "0.875rem",
			fontWeight: 400,
			lineHeight: 1.43,
			letterSpacing: "0.01071em"
		},
		button: {
			fontSize: "0.875rem ",
			fontWeight: 500,
			lineHeight: 1.75,
			letterSpacing: "0.02857em",
			textTransform: "uppercase"
		},
		caption: {
			fontSize: "0.8rem",
			fontWeight: 400,
			lineHeight: 1.66,
			letterSpacing: "0.03333em"
		},
		overline: {
			fontSize: "0.875rem",
			fontWeight: 400,
			lineHeight: 2.66,
			letterSpacing: "0.08333em",
			textTransform: "uppercase"
		}
	},
	spacing: factor => `${1.5 ** (factor - 3)}ex`
});

export default customTheme;
export { colors, status };
