import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import useAddScript from "../../hooks/useAddScript";

const libraryAwareHomePageWidgetUrl =
	"//www.libraryaware.com/Widgets/Show/eb3d51fc-4854-4257-bdcf-9daa79afa595";
const useStyles = makeStyles(theme => ({
	heroContent: {
		padding: theme.spacing(9, 0, 4)
	}
}));

/**
 * A Hero component meant to be used on LibraryAware's initial landing page. Currently uses a LibraryAware widget.
 *
 * @export
 * @returns
 */
export default function HomeHero(props) {
	useAddScript(libraryAwareHomePageWidgetUrl);

	const classes = useStyles();

	return (
		<div className={classes.heroContent}>
			<Grid container justify="center">
				<div className="libraryaware_widget_eb3d51fc48544257bdcf9daa79afa595"></div>
			</Grid>
		</div>
	);
}
