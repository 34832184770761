import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme => ({
	footer: {
		backgroundColor: theme.palette.lalight,
		padding: theme.spacing(6),
		color: theme.palette.ladark
	}
}));

/**
 * The footer component used on the LibraryAware.com home landing page
 *
 * @export
 * @param {*} { copyrightText, links } 
 * @returns
 */
export default function Footer({ copyrightText, links }) {
	const classes = useStyles();

	return (
		<footer className={classes.footer}>
			<Typography variant="body1" align="center" gutterBottom>
				{"Copyright © "}
				{new Date().getFullYear()} {` ${copyrightText}`}
			</Typography>

			<Grid container spacing={4} justify="center" alignItems="center">
				{links.map(link => (
					<Grid item key={link.url}>
						<Link href={link.url} color="inherit" target="_blank">
							{link.title}
						</Link>
					</Grid>
				))}
			</Grid>
		</footer>
	);
}

Footer.propTypes = {
	/* The copyright text displayed inside of the footer */
	copyrightText: PropTypes.string.isRequired,
	/* An array of objects containing the hyperlinks to display at the bottom of the footer */
	links: PropTypes.array
};
