const green = {
	50: "#E6F3ED",
	100: "#cce7dc",
	200: "#99cfb9",
	300: "#66b895",
	400: "#33a072",
	500: "#00884f",
	600: "#006d3f",
	700: "#00522f",
	800: "#003620",
	900: "#001b10",
	A100: "#b9f6ca",
	A200: "#69f0ae",
	A400: "#00e676",
	A700: "#00c853"
};

export default green;
