const red = {
	50: "#FDE6ED",
	100: "#FBCCDB",
	200: "#F799B6",
	300: "#F36692",
	400: "#EF336D",
	500: "#eb0049",
	600: "#BC003A",
	700: "#8D002C",
	800: "#5E001D",
	900: "#2F000F",
	A100: "#ff8a80",
	A200: "#ff5252",
	A400: "#ff1744",
	A700: "#d50000"
};

export default red;
