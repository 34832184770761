const grey = {
	50: "#F1F1F1",
	100: "#e4e4e4",
	200: "#c8c8c8",
	300: "#adadad",
	400: "#919191",
	500: "#767676",
	600: "#5e5e5e",
	700: "#474747",
	800: "#2f2f2f",
	900: "#181818",
	A100: "#d5d5d5",
	A200: "#aaaaaa",
	A400: "#303030",
	A700: "#616161"
};

export default grey;
